import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, HSection } from "components/common"
import Footer from "components/navigation/Footer"

import { device, useContentfulSection, useMeta } from "utils"
import Nav from "components/navigation/Nav"
import MobileNav from "src/components/navigation/mobile/MobileNav"

const ErrorPage = ({ data: { error } }) => {
  const {
    hero_400: { title, subtitle, media },
  } = useContentfulSection(error.sections)

  const meta = useMeta(error)

  return (
    <Layout>
      <Seo {...meta} />
      <Nav />
      <MobileNav />

      <Wrap>
        <HSection
          className="messageBox"
          titleAs="h1"
          align="left"
          title={title}
          subtitle={subtitle}
          media={media}
        />
      </Wrap>
      <Footer />
    </Layout>
  )
}

const Wrap = styled.div`
  position: relative;

  z-index: 1;
  flex: 1;
  min-height: calc(var(--vh, 1vh) * 70);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .messageBox {
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    .h_tgContainer {
      --tg-max-width: 800px;

      .title {
        margin-bottom: var(--sp-16);
      }
      .subtitle {
        white-space: break-spaces;

        a {
          color: var(--balance);
          font-size: 1rem;
        }
      }
    }

    .h_mediaContainer {
      width: 250px;

      @media ${device.laptop} {
        width: 100%;
      }
    }
  }
`

export const query = graphql`
  {
    error: contentfulPage(pageId: { eq: "400" }) {
      ...Page
    }
  }
`

export default ErrorPage
